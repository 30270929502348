import revive_payload_client_NZn76CTjww from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_f1bzwcxT7J from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ivCUlDKJdg from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TmPtsZofp0 from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_24gM3GQFUX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7QqCbdHiIn from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yQ4RERmdGY from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pzgT0z1RcM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_s7ONPaycw9 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_qmVhvHg6gb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_fGJatZs1fc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_QiWSP2l2CV from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_mHRE6e4xwL from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_BJKJm9wZwg from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_rollup@4.24.0_vite@5.4.9_vue@3.5.12/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import motion_oH0dWZs9gi from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.6_rollup@4.24.0_vue@3.5.12/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_WoYiHY2EFc from "/vercel/path0/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@4.24.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_IfcldbFAwz from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_rzev2x9c91 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_KBZPCoXOkX from "/vercel/path0/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_nuxt@3.13.2_rollup@4.24.0_vue@3.5.12/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_Az5qk4PIAQ from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.24.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_camgVsEp7a from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_@vite-pwa+assets-generator@0.2.6_rollup@4.24.0_vite@5.4.9/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_yu5AxSnleo from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_@vite-pwa+assets-generator@0.2.6_rollup@4.24.0_vite@5.4.9/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import gtm_cXsUZobmSF from "/vercel/path0/plugins/gtm.ts";
import vue_skeletor_qwqWpfYvoX from "/vercel/path0/plugins/vue-skeletor.js";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/plugins/bugsnag.ts";
export default [
  revive_payload_client_NZn76CTjww,
  unhead_f1bzwcxT7J,
  router_ivCUlDKJdg,
  _0_siteConfig_TmPtsZofp0,
  payload_client_24gM3GQFUX,
  navigation_repaint_client_7QqCbdHiIn,
  check_outdated_build_client_yQ4RERmdGY,
  chunk_reload_client_pzgT0z1RcM,
  plugin_vue3_s7ONPaycw9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qmVhvHg6gb,
  titles_fGJatZs1fc,
  defaults_QiWSP2l2CV,
  siteConfig_mHRE6e4xwL,
  inferSeoMetaPlugin_BJKJm9wZwg,
  motion_oH0dWZs9gi,
  nuxt_plugin_WoYiHY2EFc,
  switch_locale_path_ssr_IfcldbFAwz,
  i18n_rzev2x9c91,
  plugin_KBZPCoXOkX,
  plugin_Az5qk4PIAQ,
  pwa_icons_camgVsEp7a,
  pwa_client_yu5AxSnleo,
  gtm_cXsUZobmSF,
  vue_skeletor_qwqWpfYvoX,
  bugsnag_tkMQfOjcVZ
]