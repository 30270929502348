import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as locations1EMzLCbk3EMeta } from "/vercel/path0/pages/locations.vue?macro=true";
import { default as _91apartmentSlug_93R1oQEqMRb2Meta } from "/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue?macro=true";
import { default as bookSyrhQB7qKbMeta } from "/vercel/path0/pages/properties/[slug]/book.vue?macro=true";
import { default as indexEmc5cCZHhaMeta } from "/vercel/path0/pages/properties/[slug]/index.vue?macro=true";
import { default as _91_91pageNumber_93_93fOSSPbFl8HMeta } from "/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue?macro=true";
import { default as _91slug_93MJFUoUvQ9jMeta } from "/vercel/path0/pages/stories/[slug].vue?macro=true";
import { default as component_45stubdTPnjpShZeMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubdTPnjpShZe } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.12_eslint@8.57.1_less@4.2.0_rollup@4.24.0_stylelint@14.16.1_typ_7z2dca56d672jx5biwvveia62i/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl",
    path: "/nl/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "locations___nl",
    path: "/nl/locations",
    component: () => import("/vercel/path0/pages/locations.vue")
  },
  {
    name: "locations___en",
    path: "/locations",
    component: () => import("/vercel/path0/pages/locations.vue")
  },
  {
    name: "properties-slug-apartmentSlug___nl",
    path: "/nl/properties/:slug()/:apartmentSlug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue")
  },
  {
    name: "properties-slug-apartmentSlug___en",
    path: "/properties/:slug()/:apartmentSlug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue")
  },
  {
    name: "properties-slug-book___nl",
    path: "/nl/properties/:slug()/book",
    meta: bookSyrhQB7qKbMeta || {},
    component: () => import("/vercel/path0/pages/properties/[slug]/book.vue")
  },
  {
    name: "properties-slug-book___en",
    path: "/properties/:slug()/book",
    meta: bookSyrhQB7qKbMeta || {},
    component: () => import("/vercel/path0/pages/properties/[slug]/book.vue")
  },
  {
    name: "properties-slug___nl",
    path: "/nl/properties/:slug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/index.vue")
  },
  {
    name: "properties-slug___en",
    path: "/properties/:slug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/index.vue")
  },
  {
    name: "stories-page-pageNumber___nl",
    path: "/nl/stories/:page?/:pageNumber?",
    component: () => import("/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue")
  },
  {
    name: "stories-page-pageNumber___en",
    path: "/stories/:page?/:pageNumber?",
    component: () => import("/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue")
  },
  {
    name: "stories-slug___nl",
    path: "/nl/stories/:slug()",
    component: () => import("/vercel/path0/pages/stories/[slug].vue")
  },
  {
    name: "stories-slug___en",
    path: "/stories/:slug()",
    component: () => import("/vercel/path0/pages/stories/[slug].vue")
  },
  {
    name: component_45stubdTPnjpShZeMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubdTPnjpShZe
  }
]